//@ts-nocheck
import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate, Link } from "react-router-dom";
import { HomeComponent } from './components/HomeComponent';

import { Amplify, Auth } from 'aws-amplify';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import '@aws-amplify/ui-react/styles.css';
import { ProfileComponent } from './components/ProfileComponent';
import { BaseProvider } from './hooks/BaseContext';
import { IconButton } from '@material-ui/core';
import { ProposalComponent } from './components/ProposalComponent';
import { justCollabIcon } from './icons';

Amplify.configure({
  Auth: {
    region: "ap-south-1",
    userPoolId: "ap-south-1_Ac1rvyjdQ",
    userPoolWebClientId: "6mg7d2c93edh5nuo8mvstgq7gp"
  }
});



function App() {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopClose = () => {
    setAnchorEl(null);
  };

  return (

    <Router>
      <div className="App">

        <BaseProvider>
          <div className="nav-bar">

            <img className="just-collab-logo" src={justCollabIcon} />

            <IconButton
              aria-label="more"
              aria-controls="volyume-menu"
              aria-haspopup="true"
              onClick={handlePopOpen}
              className='nav-menu-button'
            >
              <MenuIcon style={{ color: 'white' }} />
            </IconButton>

            <Menu
              id="volyume-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handlePopClose}
              className='navbar-menu'
            >
              <MenuItem className='logo-menu-item' onClick={handlePopClose}>
                <Link className='logo-menu-route' to="/">Home</Link>
              </MenuItem>
              <MenuItem className='logo-menu-item' onClick={handlePopClose}>
                <Link className='logo-menu-route' to="/profile">Profile</Link>
              </MenuItem>
              <MenuItem className='logo-menu-item' onClick={() => { handlePopClose(); Auth.signOut() }}>
                <Link className='logo-menu-route' to="/">Logout</Link>
              </MenuItem>
            </Menu>

          </div>
          <Routes className="test-css">
            {/* <Route element={<SignupComponent />} path={'/signup'} /> */}
            <Route element={<HomeComponent />} path={'/'} />
            <Route element={<ProfileComponent />} path={'/profile'} />
            <Route element={<ProposalComponent />} path={'/proposal/:proposal_id'} />
            <Route path='*' element={<Navigate to="/" />} />
          </Routes>
        </BaseProvider>


      </div>
    </Router>

  );
}

export default App;