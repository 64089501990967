export interface BaseInfoResponse {
	kindleMailId: string;
	titles: Array<LogItem>;
}

export type SharedItem = {
	mimeType: string;
	data: string;
	extraData: any;
};

export interface BaseInfoStatus extends BaseInfoResponse {
	loading: boolean;
	status?: 'ERROR';
}

export interface BaseContextInterface {
	baseInfo: BaseInfoStatus;
	sharedData: string;
	setSharedData: (link: string) => void;
}

export interface LogItem {
	title: string;
	url: string;
}

export enum GeneratorResponseStatus {
	NEW_REQUEST = 'NEW_REQUEST',
	RESEND_REQUEST = 'RESEND_REQUEST',
	RESEND_COMPLETED = 'RESEND_COMPLETED',
	ERROR = 'ERROR'
}

export interface NewRequestServerResponse {
	status: GeneratorResponseStatus;
	title: string;
}

export interface ResendRequestServerResponse {
	requestId: string;
	status: GeneratorResponseStatus;
}

export interface ErrorRequestServerResponse {
	status: GeneratorResponseStatus.ERROR;
}

export enum LoadStates {
	NOT_STARTED,
	LOADING,
	COMPLETED
}

export type GeneratorResponse = NewRequestServerResponse | ResendRequestServerResponse | ErrorRequestServerResponse;

export interface Tag {
	tag_id: string;
	tag_name: string;
}

export interface Campaign {
	campaign_id: string;
	brand: string;
	name: string;
	description: string;
	image: string;
	tags: string[];
}

export interface LoginStatus {
	loading: boolean;
	isLoggedIn: boolean;
	userDetails?: {
		name: string;
		email: string;
	};
}

export interface InfluencerAttributes {
	'preferred_username'?:string,
	'custom:link_display'?: string,
    // 'custom:youtube_link'?: string,
    // 'custom:instagram_link'?: string,
	// 'custom:facebook_link'?: string,
	'custom:extra_link1'?: string,
	'custom:extra_link2'?: string,
	'custom:extra_link3'?: string,
	'custom:extra_link4'?: string,
	'custom:extra_link5'?: string,
	'custom:extra_label1'?: string,
	'custom:extra_label2'?: string,
	'custom:extra_label3'?: string,
	'custom:extra_label4'?: string,
	'custom:extra_label5'?: string,
	// 'custom:alt_phone_number'?: string
}

export interface Proposal {
	prop_id: string,
	brand_id: string,
    name: string,
    brand: string,
	aboutBrand?: string,
	brand_email: string,
    brandHandle: string,
    position?: string,
    phone_number: string,
    deliverables: string,
    time_period?: string,
    comments?: string,
    on_instagram: boolean,
    on_youtube: boolean,
    on_moj: boolean,
    on_facebook: boolean,
	status: PROPSTATUS
}

export enum PROPSTATUS {
	REPLY_BY_EMAIL = 'REPLY_BY_EMAIL',
	REPLY_BY_WHATSAPP= 'REPLY_BY_WHATSAPP',
	REQUEST_ASSISTANCE= 'REQUEST_ASSISTANCE',
	REJECTED= 'REJECTED',
	NEW='NEW'
}