import { Checkbox, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { clipIcon, nameIcon, starIcon, usernameIcon } from '../../icons';
import { InfluencerAttributes } from '../../interfaces';
import { checkURLAvailability, getProfile, updateProfile } from '../../utilities/backend';
import { StyledButton, StyledInputField } from '../../utilities/BaseComponents';
import { ProfileSavedComponent } from '../ProfileSaveComponent';
import { ImageUploader } from './ImageUploader';
import './styles.css';

export const ProfileComponent = () => {

    const [whatsappChecked, setWhatsappChecked] = useState<boolean>(false);
    const [influencerAttr, setInfluencerAttr] = useState<InfluencerAttributes>({
        'custom:link_display': '',
        'custom:extra_link1': '',
        'custom:extra_link2': '',
        'custom:extra_link3': '',
        'custom:extra_link4': '',
        'custom:extra_link5': '',
        'custom:extra_label1': '',
        'custom:extra_label2': '',
        'custom:extra_label3': '',
        'custom:extra_label4': '',
        'custom:extra_label5': '',
    });
    const [displayURL, setDisplayURL] = useState('');

    const [urlAvailable, setUrlAvailable] = useState<true | false | 'LOADING'>('LOADING');
    const [profileSaved, setProfileSaved] = useState<boolean>(false);
    const [loadingProgress, setLoadingProgress] = useState(false);


    const saveHandler = () => {

        setLoadingProgress(true);

        updateProfile(influencerAttr, {
            whatsapp: whatsappChecked,
            unique_display: displayURL
        },
            []
        ).then(() => setProfileSaved(true))
        .catch(()=> setLoadingProgress(false));
    }

    const checkURLAvailabilityHandler = () => {
        console.log('hitting availablility')
        setUrlAvailable('LOADING');
        checkURLAvailability(displayURL).then(({ available }) => {
            setUrlAvailable(available);
        });
    };

    const setAttributeHandler = (event: any, attrib_name: string) => {
        setInfluencerAttr((Attr) =>
            ({ ...Attr, [attrib_name]: event }))
    }

    useEffect(() => {
        getProfile().then((data) => {
            setInfluencerAttr(data.attribs);
            setWhatsappChecked(data.profile.whatsapp);
            setDisplayURL(data.profile.unique_display);
        })
    }, [])

    if (profileSaved) return (<ProfileSavedComponent username={displayURL} />)


    return (
        <div className="profile-container" >
            <div className="profile-header-text">Display Image</div>

            <ImageUploader />

            <div className="profile-header-text">Complete your Profile</div>


            <div className="profile-url-field-container">
                <div className="profile-url-input">
                    <StyledInputField
                        inputContainerClass='profile-input-field'
                        placeHolderText='Username'
                        inputImage={usernameIcon}
                        value={displayURL || ''}
                        onBlurFunction={checkURLAvailabilityHandler}
                        setValueFunction={(event: any) => setDisplayURL(event.replace(/ /g,'').toLowerCase())}
                    />
                    {
                        urlAvailable !== 'LOADING' && (<div className="availability-indicator-text" style={{ color: (urlAvailable == true ? 'green' : 'red') }}>
                            {urlAvailable == true ? 'URL available' : 'URL not available!'}
                        </div>)
                    }
                </div>
            </div>

            <StyledInputField
                inputContainerClass='profile-input-field'
                placeHolderText='Display Name'
                inputImage={nameIcon}
                value={influencerAttr['custom:link_display'] || ''}
                setValueFunction={(event: any) => setAttributeHandler(event, 'custom:link_display')}
            />


            <StyledInputField
                inputContainerClass='profile-input-field'
                placeHolderText='Custom link 1 Name'
                inputImage={starIcon}
                value={influencerAttr['custom:extra_label1'] || ''}
                setValueFunction={(event: any) => setAttributeHandler(event, 'custom:extra_label1')}
            />

            <StyledInputField
                inputContainerClass='profile-input-field'
                placeHolderText='Custom link 1'
                inputImage={clipIcon}
                value={influencerAttr['custom:extra_link1'] || ''}
                setValueFunction={(event: any) => setAttributeHandler(event, 'custom:extra_link1')}
            />

            <StyledInputField
                inputContainerClass='profile-input-field'
                placeHolderText='Custom link 2 Name'
                inputImage={starIcon}
                value={influencerAttr['custom:extra_label2'] || ''}
                setValueFunction={(event: any) => setAttributeHandler(event, 'custom:extra_label2')}
            />

            <StyledInputField
                inputContainerClass='profile-input-field'
                placeHolderText='Custom link 2'
                inputImage={clipIcon}
                value={influencerAttr['custom:extra_link2'] || ''}
                setValueFunction={(event: any) => setAttributeHandler(event, 'custom:extra_link2')}
            />

            <StyledInputField
                inputContainerClass='profile-input-field'
                placeHolderText='Custom link 3 Name'
                inputImage={starIcon}
                value={influencerAttr['custom:extra_label3'] || ''}
                setValueFunction={(event: any) => setAttributeHandler(event, 'custom:extra_label3')}
            />

            <StyledInputField
                inputContainerClass='profile-input-field'
                placeHolderText='Custom link 3'
                inputImage={clipIcon}
                value={influencerAttr['custom:extra_link3'] || ''}
                setValueFunction={(event: any) => setAttributeHandler(event, 'custom:extra_link3')}
            />

            <div className="whatsapp-checkbox-container">
                <div className="whatsapp-checkbox">Allow us to update via Whatsapp</div>
                <Checkbox
                    checked={whatsappChecked}
                    onChange={(event: any) => setWhatsappChecked(event.target.checked)}
                    color="default"
                />
            </div>

            {loadingProgress ?
                (<CircularProgress className='loading-progress-indicator' />) : (
                    <StyledButton buttonText='Submit' buttonClass='styled-button-profile-save' handlerFunction={saveHandler} />)
            }
        </div>
    )
}