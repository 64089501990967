import React from 'react';
import { Link } from 'react-router-dom';
import { backIcon } from '../../../icons';
import './styles.css';

export const ChangedPasswordComponent = () => {
    return (
        <div className='changed-password-user-container'>
            <div className="changed-password-notify">The password has been Changed</div>

            <Link to={'/login'} className='changed-password-dashboard-button'>
                <img src={backIcon} className='changed-password-back'/> Login
            </Link>

            <div className="changed-password-link-display-subtext">Powered by Watevr</div>
        </div>
    )
}