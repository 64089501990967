import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ErrorMessageDisplay, StyledButton, StyledInputField } from '../../utilities/BaseComponents';
import './styles.css';

import { emailIcon, facebookIcon, instagramIcon, nameIcon, passwordIcon, phoneIcon, youtubeIcon } from '../../icons';
import { allTrueValidation, emailValid, phoneValid } from '../../utilities/tools';
import { CircularProgress } from '@material-ui/core';
export const SignupComponent = () => {

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [phone, setPhone] = useState<number | null>();
    const [altPhone, setAltPhone] = useState<number | null>();
    const [name, setName] = useState('');
    const [instaLink, setInstaLink] = useState('');
    const [ytLink, setYtLink] = useState('');
    const [fbLink, setFbLink] = useState('');

    const passwordMatch = (!!confirmPassword === false) || (password === confirmPassword);

    const [validationCheck, setValidationCheck] = useState(false);
    const allMandatoryFilled = allTrueValidation([name, email, password, confirmPassword, phone]) && (!!instaLink || !!ytLink ||  !!fbLink);
    const allValidationPass = allTrueValidation([allMandatoryFilled, emailValid(email), phoneValid(phone as number)]);

    const [errorMessage, setErrorMessage] = useState('');

    const [loadingProgress, setLoadingProgress] = useState(false);

    const signUpHandler = () => {
        setLoadingProgress(true);
        setErrorMessage('');

        if (!allValidationPass) {
            setValidationCheck(true);
            setLoadingProgress(false);
            return;
        }

        Auth.signUp({
            username: email,
            password: password,
            attributes: {
                name: name,
                email: email,
                phone_number: '+91' + phone,
                'custom:alt_phone_number': altPhone,
                'custom:youtube_link': ytLink,
                'custom:instagram_link': instaLink,
                'custom:facebook_link': fbLink
            }
        }).then(() => {
            setLoadingProgress(false);
            navigate('/awaiting-approval')
        })
            .catch((data) => {
                setLoadingProgress(false);
                setErrorMessage(data.message)
            })
    }

    const errorMessageHandler = () => {
        if (!allMandatoryFilled) return 'All the mandatory fields needs to be filled';
        if (!passwordMatch) return 'password do not match';
        if (!emailValid(email)) return 'Email is not valid';
        if (!phoneValid(phone)) return 'Phone number is not valid'
    }


    return (
        <div className="signup-container" >
            <div className="signup-container-header">Signup</div>

            <Link to={'./login'} className="login-link-text">Already have an account?</Link>

            <StyledInputField inputImage={nameIcon} inputContainerClass='signup-input-field' placeHolderText='Name*' value={name} setValueFunction={setName} errorHighlight={validationCheck && (!name)} />
            <StyledInputField inputImage={emailIcon} inputContainerClass='signup-input-field' placeHolderText='Email*' value={email} setValueFunction={setEmail} errorHighlight={validationCheck && (!email || !emailValid(email))} />


            <StyledInputField
                inputType='password'
                inputContainerClass={'signup-input-field ' + (!passwordMatch ? 'password-mismatch-border' : null)}
                placeHolderText='Password*'
                inputImage={passwordIcon}
                value={password}
                setValueFunction={setPassword}
                errorHighlight={validationCheck && (!password)}
            />

            <StyledInputField
                inputType='password'
                inputContainerClass={'signup-input-field ' + (!passwordMatch ? 'password-mismatch-border' : null)}
                inputImage={passwordIcon}
                placeHolderText='Confirm Password*'
                value={confirmPassword}
                setValueFunction={setConfirmPassword}
                errorHighlight={validationCheck && (!confirmPassword)}
            />

            <div className="platform-link-holder">
                <div className="platform-link-logo-holder">
                    <img src={instagramIcon} className="platform-link-logo" />
                </div>
                <StyledInputField inputContainerClass='platform-link-input' placeHolderText='https://www.instagram.com/' value={instaLink} setValueFunction={setInstaLink} errorHighlight={validationCheck && (!instaLink && !ytLink &&  !fbLink)} />
            </div>

            <div className="platform-link-holder">
                <div className="platform-link-logo-holder">
                    <img src={youtubeIcon} className="platform-link-logo" />
                </div>
                <StyledInputField inputContainerClass='platform-link-input' placeHolderText='https://www.youtube.com/' value={ytLink} setValueFunction={setYtLink} errorHighlight={validationCheck && (!instaLink && !ytLink &&  !fbLink)} />
            </div>

            <div className="platform-link-holder">
                <div className="platform-link-logo-holder">
                    <img src={facebookIcon} className="platform-link-logo" />
                </div>
                <StyledInputField inputContainerClass='platform-link-input' placeHolderText='https://www.facebook.com/' value={fbLink} setValueFunction={setFbLink} errorHighlight={validationCheck && (!instaLink && !ytLink &&  !fbLink)} />
            </div>

            <StyledInputField inputImage={phoneIcon} inputType='number' inputContainerClass='signup-input-field' placeHolderText='Phone Number*' value={phone} setValueFunction={setPhone} errorHighlight={validationCheck && (!phone || !phoneValid(phone))} />
            <StyledInputField inputImage={phoneIcon} inputType='number' inputContainerClass='signup-input-field' placeHolderText='Alternative Contact Number' value={altPhone} setValueFunction={setAltPhone} />

            {validationCheck && (<ErrorMessageDisplay errorMessage={
                errorMessageHandler()
            } />)}

            <ErrorMessageDisplay errorMessage={errorMessage} />

            {loadingProgress ?
                (<CircularProgress className='loading-progress-indicator' />) : (
                    <StyledButton buttonText='Signup' handlerFunction={signUpHandler} />
                )
            }
            <div className="signup-link-display-subtext">Powered by Watevr</div>
        </div>
    )
}

