import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { BaseContext } from '../../hooks/BaseContext';
import useWindowDimensions from '../../hooks/WindowDimensions';
import { PROPSTATUS } from '../../interfaces';
import './styles.css';

export const HomeComponent = () => {


    const { proposals, loginStatus } = useContext(BaseContext);
    const { width } = useWindowDimensions();

    return (
        <div className="home-container" >
            <div className="home-welcome-container">
                <div className="welcome-text">Welcome back</div>
                <div className="welcome-text-name">{loginStatus.userDetails?.name}</div>
            </div>

            <div className="proposal-list-container">
                {
                    proposals.map((proposal, index) => (
                        <Link
                            to={"/proposal/" + proposal.prop_id}
                            className='single-proposal-item'
                            style={
                                (proposal.status == PROPSTATUS.NEW)||(proposal.status == undefined)
                                 ?({backgroundColor: '#f2f3f7'}):({backgroundColor: '#E1E2E3'})}
                            key={index}
                        >
                            {proposal.brand}
                        </Link>
                    ))
                }
            </div>
        </div>
    )
}