import { CircularProgress, TextareaAutosize } from '@material-ui/core';
import React, { useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { BaseContext } from '../../hooks/BaseContext';
import { longtextIcon, nameIcon, instagramIcon, facebookIcon, youtubeIcon, websiteIcon } from '../../icons';
import { PROPSTATUS } from '../../interfaces';
import { updateProposal } from '../../utilities/backend';
import { StyledButton, StyledInputField, StyledTextArea } from '../../utilities/BaseComponents';
import { assistProposalWhatsappGenerator, proposalResponseMailGenerator, proposalResponseWhatsappGenerator } from '../../utilities/tools';
import { ProposalRejectedComponent } from '../ProposalRejected';
import './styles.css'

export const ProposalComponent = () => {

    const [rejectComments, setRejectComments] = useState('');
    const [rejected, setRejected] = useState(false);
    const navigate = useNavigate();

    //@ts-ignore
    const { proposal_id }: { proposal_id: string } = useParams();

    const [MailIndicator, setMailIndicator] = useState(false);
    const [WhtspIndicator, setWhtspIndicator] = useState(false);
    const [assistanceIndicator, setAssistanceIndicator] = useState(false);
    const [rejectIndicator, setRejectIndicator] = useState(false);

    const { getProposalById, refreshProposals, loginStatus } = useContext(BaseContext)

    if (rejected) return <ProposalRejectedComponent />

    const proposal = getProposalById(proposal_id);

    const replyMailLink = proposalResponseMailGenerator(proposal.brand, loginStatus.userDetails?.name || '', proposal.brand_email)
    const replyWhatsappLink = "https://wa.me/91" + proposal.phone_number + "?text=" + proposalResponseWhatsappGenerator(proposal.brand, loginStatus.userDetails?.name || '');
    const assistanceWhatsapp = "https://wa.me/917025200007?text=" + assistProposalWhatsappGenerator(proposal_id, proposal.brand, loginStatus.userDetails?.name || '');

    const handleAssist = () => {
        setAssistanceIndicator(true);
        updateProposal({ prop_id: proposal_id, action: PROPSTATUS.REQUEST_ASSISTANCE }).then(() => {
            refreshProposals();
            navigate('/');
            setAssistanceIndicator(false);
        }).catch(() => setAssistanceIndicator(false))
    };

    const handleReject = () => {
        setRejectIndicator(true);
        updateProposal({ prop_id: proposal_id, action: PROPSTATUS.REJECTED, comment: rejectComments }).then(() => {
            refreshProposals();
            setRejected(true);
            setRejectIndicator(false);
        }).catch(() => setRejectIndicator(false))
    }

    const handleReplyMail = () => {
        setMailIndicator(true);
        updateProposal({ prop_id: proposal_id, action: PROPSTATUS.REPLY_BY_EMAIL }).then(() => {
            refreshProposals();
            navigate('/');
            setMailIndicator(false);
        }).catch(() => setMailIndicator(false))
    }

    const handleReplyWhatsapp = () => {
        setWhtspIndicator(true);
        updateProposal({ prop_id: proposal_id, action: PROPSTATUS.REPLY_BY_WHATSAPP }).then(() => {
            refreshProposals();
            navigate('/');
            setWhtspIndicator(false);
        }).catch(() => setWhtspIndicator(false))
    }

    return (
        <div className="proposal-view-container">
            <div className="proposal-brand-name">
                {proposal.brand}
            </div>

            <StyledInputField
                value={proposal.brandHandle}
                inputImage={websiteIcon}
                readonly={true}
            />

            <StyledInputField
                inputContainerClass='proposal-input-field'
                value={proposal.name + (!!proposal.position ? (' / ' + proposal.position) : '')}
                inputImage={nameIcon}
                readonly={true}
            />

            <StyledTextArea
                inputContainerClass='proposal-text-area-field'
                value={proposal.aboutBrand}
                label='About the brand'
                labelImage={longtextIcon}
                readonly={true}
            />


            <div className="proposal-label">Preffered Platforms for content publishing</div>

            <div className="proposal-platforms-view">
                {proposal.on_instagram && (
                    <div className="proposal-platform-selected">
                        <img src={instagramIcon} />
                    </div>
                )}

                {proposal.on_facebook && (
                    <div className="proposal-platform-selected">
                        <img src={facebookIcon} />
                    </div>
                )}

                {proposal.on_youtube && (
                    <div className="proposal-platform-selected">
                        <img src={youtubeIcon} />
                    </div>
                )}
            </div>

            <StyledTextArea
                inputContainerClass='proposal-text-area-field'
                value={proposal.deliverables}
                label='Campaign details'
                labelImage={longtextIcon}
                readonly={true}
            />

            {MailIndicator ?
                (<CircularProgress className='loading-progress-indicator' />) : (
                    <a href={replyMailLink} style={{width: '100%'}} target="_blank">
                        <StyledButton buttonText='Reply via Mail' buttonClass='styled-anchor-mail' handlerFunction={handleReplyMail} />
                    </a>
                )
            }
            {WhtspIndicator ?
                (<CircularProgress className='loading-progress-indicator' />) : (
                    <a href={replyWhatsappLink} style={{width: '100%'}} target="_blank">
                        <StyledButton buttonText='Reply via Whatsapp' buttonClass='styled-anchor-whatsapp' handlerFunction={handleReplyWhatsapp} />
                    </a>
                )
            }

            <div className="proposal-label">Need our support  to manage this campaign for you?</div>

            {assistanceIndicator ?
                (<CircularProgress className='loading-progress-indicator' />) : (
                    <a href={assistanceWhatsapp} style={{width: '100%'}} target="_blank">
                        <StyledButton buttonText='Tap Here!' buttonClass='proposal-assist-button' handlerFunction={handleAssist} />
                    </a>
                )
            }
            <div className="proposal-not-interested-label">
                Not Interested?
            </div>

            <StyledTextArea
                inputContainerClass='proposal-text-area-field'
                value={rejectComments}
                setValueFunction={setRejectComments}
                label='Comments'
                labelImage={longtextIcon}
                readonly={false}
            />

            {rejectIndicator ?
                (<CircularProgress className='loading-progress-indicator' />) : (
                    <StyledButton buttonText='Remove the proposal' buttonClass='styled-anchor-reject' handlerFunction={handleReject} />
                )
            }

            <div className="proposal-link-display-subtext">Powered by Watevr</div>

        </div>
    )

}