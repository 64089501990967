import React from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledButton } from '../../utilities/BaseComponents';
import './styles.css'

export const ProfileSavedComponent = ({username}:{username: string}) => {

    const navigate = useNavigate();

    const handleDashboardNavigate = () => {
        navigate("/");
    }

    const handleProfileView = () => {
        window.open(`https://www.justcollab.in/${username}`,'_blank')
    }

    return (
        <div className='profile-save-container'>
            <div className="profile-save-hurray">Hurray</div>
            <div className="profile-save-text">You have completed your profile</div>

            <StyledButton buttonClass='profile-save-button' buttonText='Check your dashboard' handlerFunction={handleDashboardNavigate} />
            <StyledButton buttonClass='profile-save-button' buttonText='Preview my Profile' handlerFunction={handleProfileView} />

            <div className="saved-profile-link-display-subtext">Powered by Watevr</div>
        </div>
    )
}