import { CircularProgress } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ErrorMessageDisplay, StyledButton, StyledInputField } from '../../utilities/BaseComponents';
import { allTrueValidation } from '../../utilities/tools';
import { ChangedPasswordComponent } from './ChangedPassword';
import './styles.css';

export const ForgotPassComponent = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [email, setEmail] = useState(searchParams.get("username"));
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [resetCode, setResetCode] = useState('');

    const [hasForgot, setHasForgot] = useState(false);

    const [validationCheck, setValidationCheck] = useState(false);
    const allMandatoryFilled = allTrueValidation([resetCode, confirmPassword, password]);
    const [errorMessage, setErrorMessage] = useState('');

    const [passwordChanged, setPasswordChanged] = useState(false);

    const [loadingProgress, setLoadingProgress] = useState(false);

    if (passwordChanged) return (<ChangedPasswordComponent />)

    const handleForget = () => {
        setLoadingProgress(true);
        setErrorMessage('');
        Auth.forgotPassword(email as string).then((data) => {
            setHasForgot(true);
            setLoadingProgress(false);
        })
            .catch((err) => {
                setErrorMessage(err.message);
                setLoadingProgress(false);
            });
    };

    const handleChangePassword = () => {
        setErrorMessage('');
        setLoadingProgress(true);
        if (!allMandatoryFilled) {
            setErrorMessage('Enter all the mandatory fields')
            setValidationCheck(true);
            setLoadingProgress(false);
            return;
        }

        Auth.forgotPasswordSubmit(email as string, resetCode, password)
            .then((data) => {
                console.log(data);
                setPasswordChanged(true);
                setLoadingProgress(false);
            })
            .catch((err) => {
                setLoadingProgress(false);
                setErrorMessage(err.message)
            });
    }

    const passMatch = (!!confirmPassword === false) || (password === confirmPassword);

    return (
        <div className="forgot-container" >
            {!hasForgot ?
                (<>
                    <div className="forgot-container-header">Forgot Password</div>

                    <StyledInputField inputContainerClass='forgot-input-field' placeHolderText='Email' value={email} setValueFunction={setEmail} />

                    {loadingProgress ?
                        (<CircularProgress className='loading-progress-indicator' />) : (
                            <StyledButton buttonText='Help me figure it out' buttonClass='styled-button-forgot' handlerFunction={handleForget} />
                        )
                    }
                    <ErrorMessageDisplay errorMessage={errorMessage} />
                </>)
                :
                (
                    <>
                        <div className="forgot-container-header">Enter the code</div>
                        <StyledInputField inputType='text' inputContainerClass='forgot-input-field ' placeHolderText='Code' value={resetCode} setValueFunction={setResetCode} />

                        <div className="forgot-container-header">Enter the new password</div>
                        <StyledInputField
                            inputType='password'
                            inputContainerClass={'forgot-input-field ' + (!passMatch ? 'password-mismatch-border' : null)}
                            placeHolderText='Password' value={password}
                            setValueFunction={setPassword}
                            errorHighlight={validationCheck && (!password)}
                        />
                        <StyledInputField
                            inputType='password'
                            inputContainerClass={'forgot-input-field ' + (!passMatch ? 'password-mismatch-border' : null)}
                            placeHolderText='Confirm Password'
                            value={confirmPassword}
                            setValueFunction={setConfirmPassword}
                            errorHighlight={validationCheck && (!confirmPassword)}
                        />


                        <ErrorMessageDisplay errorMessage={errorMessage} />

                        {loadingProgress ?
                            (<CircularProgress className='loading-progress-indicator' />) : (
                                <StyledButton buttonText='Change Password' buttonClass='styled-button-forgot' handlerFunction={handleChangePassword} />
                            )
                        }
                    </>
                )}


        </div>
    )
}

