import React from 'react';
import { Link } from 'react-router-dom';
import { backIcon } from '../../icons';
import './styles.css';

export const ProposalRejectedComponent = () => {
    return (
        <div className='rejected-proposal-user-container'>
            <div className="rejected-proposal-notify">The proposal has been rejected and the brand will be notified</div>

            <Link to={'/'} className='rejected-proposal-dashboard-button'>
                <img src={backIcon} className='rejected-proposal-back'/> Back to Profile
            </Link>

            <div className="rejected-proposal-link-display-subtext">Powered by Watevr</div>
        </div>
    )
}