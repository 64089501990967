import React from 'react';
import './styles.css';

export const LoadingDisplay = ({processMessage}: {processMessage: string}) => {

    return (
        <div className="container" >
            {processMessage}...
        </div>
    )
}

