export const allTrueValidation = (states: any[]) => {
	let valid = true;
	states.forEach((state) => {
		valid = valid && !!state;
	});

	return valid;
};

export const emailValid = (email: string) => {
	const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

	return email.match(emailRegex);
};

export const phoneValid = (phone: any) => {
	if (Number(phone) > 1000000000 && Number(phone) < 9999999999) return true;

	return false;
};

export const proposalResponseMailGenerator = (brand: string, infl: string, brand_email: string) => {
	const mailBody = encodeURIComponent(`Dear ${brand},
	I hope this email finds you well. I was thrilled to receive your collaboration request. I admire ${brand} Brand's commitment to innovation and quality, and I am excited about the possibility of collaborating with you.
	
	I would be delighted to learn more about your collaboration proposal, including the specific details and expectations. Please provide me with more information on the campaign, products or services to be featured, and other relevant information.
	
	I look forward to further discussing the potential collaboration and exploring ways we can create a mutually beneficial and successful partnership. Please feel free to reach out to me with additional details or questions.
	
	Thank you for considering me for this opportunity. 
	
	Regards
	${infl}
	`)
	
	const mailSubject = encodeURIComponent(`Collaboration Inquiry with ${brand}`);

	return (`mailto:${brand_email}?&subject=${mailSubject}&body=${mailBody}`)

	;
};

export const proposalResponseWhatsappGenerator = (brand: string, infl: string) => {
	return encodeURIComponent(`👋  Hello ${brand} ! 

	Hope all's well. This is ${infl} and I’m thrilled to receive your collaboration request. I admire ${brand} Brand's innovation & quality, and excited about collaborating with you. Please provide me with more information on the campaign, products or services to be featured, and other relevant information.
	Looking forward to discussing further a successful partnership. 
	Feel free to reach out with more info or questions. 
	
	Thanks for considering me! 😊
	`)
}

export const proposalRejectMailGenerator = (brand: string, infl: string, brand_email: string) => {
	const mailBody = encodeURIComponent(`👋  Dear ${brand} ,

	I sincerely appreciate your collaboration inquiry and the opportunity to work with your brand. After careful consideration, I regret to inform you that I am unable to accept the collaboration at this time. Please know that it is not a reflection of your brand or the value of the collaboration.
	As an experienced creator, I strive to maintain authenticity and align with partnerships that truly resonate with my audience and personal brand. Unfortunately, I do not feel that this collaboration is the right fit for me at this moment.
	I sincerely appreciate your understanding and hope that we may have the opportunity to collaborate in the future. I wish you and your brand all the best in your future endeavors.
	Thank you again for considering me for this opportunity.
	
	Best regards, 
	${infl}
	
	`)
	
	const mailSubject = encodeURIComponent(`Collaboration Inquiry with ${brand}`);

	return (`mailto:${brand_email}?&subject=${mailSubject}&body=${mailBody}`)

	;
};

export const assistProposalWhatsappGenerator = (prop_id: string, brand: string, infl: string) => {
	return encodeURIComponent(`👋  Hey there! 
	Hope you're doing well. I'm ${infl} , and I'm interested in seeking an association with Watevr to execute a brand collaboration. Let's chat and explore potential opportunities. Looking forward to hearing from you!

	-----------------------
	Proposal id: ${prop_id},
	brand: ${brand}
	`)
}