import React from 'react';
import './styles.css'

export const UnconfirmedUserComponent = () => {
    return (
        <div className='unconfirmed-user-container'>
            <div className="unconfirmed-thanks">Thank you</div>
            <div className="unconfirmed-notify">Will be notified soon</div>

            <div className="unconfirmed-link-display-subtext">Powered by Watevr</div>
        </div>
    )
}