import { Auth } from 'aws-amplify';
import axios from 'axios';
import { InfluencerAttributes, Tag } from '../interfaces';

const VOLYUME_BACKEND = 'https://j3xzef70wh.execute-api.ap-south-1.amazonaws.com/prod/';
let baseTags: Tag[] = [];

export const getBaseTags = (tag_ids: string[]) => {
    return tag_ids.map((tag_id)=> baseTags.find((tag)=> tag.tag_id==tag_id)?.tag_name)
}

export const getCampaigns = async () => {
    const session = await Auth.currentSession();
    const config = {
		headers: { Authorization: `Bearer ${session.getIdToken().getJwtToken()}` }
	};

    const {data} = await axios.get(VOLYUME_BACKEND+'campaign', config);
    return data.campaignResponse;    
}

export const getProposals = async () => {
    const session = await Auth.currentSession();
    const config = {
		headers: { Authorization: `Bearer ${session.getIdToken().getJwtToken()}` }
	};

    const {data} = await axios.get(VOLYUME_BACKEND+'proposal', config);
    return data.proposals;    
}

export const isRegistered = async (campaign_id: string) => {
    const session = await Auth.currentSession();
    const config = {
		headers: { Authorization: `Bearer ${session.getIdToken().getJwtToken()}` }
	};
    const {data} = await axios.get(VOLYUME_BACKEND+ 'isregistered/'+campaign_id, config)

    return (!!data.registration);
}

export const registerCamp = async (campaign_id: string) => {
    const session = await Auth.currentSession();
    const config = {
		headers: { Authorization: `Bearer ${session.getIdToken().getJwtToken()}` }
	};

    const {data} = await axios.post(VOLYUME_BACKEND+ 'isregistered/'+campaign_id, {} , config)

}

export const updateProposal = async ({prop_id, action, comment=''}:{prop_id: string, action: string, comment?: string}) => {
    const session = await Auth.currentSession();
    const config = {
		headers: { Authorization: `Bearer ${session.getIdToken().getJwtToken()}` }
	};
    const {data} = await axios.put(VOLYUME_BACKEND+ 'proposal', {prop_id, proposalAction: action, comment} , config)
    console.log(data);
}

export const getProfile = async () => {
    const session = await Auth.currentSession();
    const config = {
		headers: { Authorization: `Bearer ${session.getIdToken().getJwtToken()}` }
	};

    const {data} = await axios.get(VOLYUME_BACKEND+'profile', config);
    baseTags=data.baseTags;
    return data;
    
}

export const updateProfile = async (user_attributes: InfluencerAttributes,profile:any, tag_ids: string[]) => {
    const session = await Auth.currentSession();
    const config = {
		headers: { Authorization: `Bearer ${session.getIdToken().getJwtToken()}` }
	};

    const {data} = await axios.post(VOLYUME_BACKEND+'profile',{user_attributes,profile,tag_ids} ,config)
    return data.updatedProfile;
    
}

export const checkURLAvailability = async (url: string) => {
    const session = await Auth.currentSession();
    const config = {
		headers: { Authorization: `Bearer ${session.getIdToken().getJwtToken()}` }
	};

    const {data} = await axios.get(VOLYUME_BACKEND+'islinkavailable/'+url, config);
    return data;
    
}

export const postPhoto = async (imageURI: string) => {
    const session = await Auth.currentSession();
    const config = {
		headers: { Authorization: `Bearer ${session.getIdToken().getJwtToken()}` }
	};

    const {data} = await axios.post(VOLYUME_BACKEND+'profileImage',{imageURI} ,config)
    return data;
    
}