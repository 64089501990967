import { CircularProgress } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { emailIcon, passwordIcon } from '../../icons';
import { ErrorMessageDisplay, StyledButton, StyledInputField } from '../../utilities/BaseComponents';
import './styles.css';

export const LoginComponent = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [loadingProgress, setLoadingProgress] = useState(false);

    const LoginHandler = () => {
        setErrorMessage('');
        setLoadingProgress(true);
        Auth.signIn(email, password).catch((err) => {
            setErrorMessage(err.name);
            setLoadingProgress(false);
        });
    }

    return (
        <div className="login-container" >

            <div className="login-container-header">Login</div>

            <StyledInputField inputImage={emailIcon} inputContainerClass='login-input-field' placeHolderText='Email' value={email} setValueFunction={setEmail} />
            <StyledInputField inputImage={passwordIcon} inputType='password' inputContainerClass='login-input-field' placeHolderText='Password' value={password} setValueFunction={setPassword} />
            <Link to={`/forgot?username=${email}`} className='forgot-password-link'>Forgot password?</Link>

            <ErrorMessageDisplay errorMessage={errorMessage} />

            {loadingProgress ?
                (<CircularProgress className='loading-progress-indicator' />) : (
                    <StyledButton buttonText='Login' buttonClass='styled-button-profile-save' handlerFunction={LoginHandler} />)
            }

            <Link to={'/signup'} className='forgot-password-link'>Create an account</Link>

        </div>
    )
}

