import { CircularProgress, Slider } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import react, { useEffect, useRef, useState } from 'react'
import AvatarEditor from 'react-avatar-editor'
import { uploadIcon } from '../../icons';
import { postPhoto } from '../../utilities/backend';
import { StyledButton } from '../../utilities/BaseComponents';

export const ImageUploader = () => {

    const avatarEditor = useRef(null);
    const [profileImageScale, setProfileImageScale] = useState(0.1);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [isImageValid, setIsImageValid] = useState(false);

    const [imageExist, setImageExist] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(false);

    //avatarEditor.current.getImageScaledToCanvas().toDataURL()

    useEffect(() => {
        Auth.currentSession().then(async (session) => {
            const sub = session.getIdToken().payload.sub;
            const image_url = 'https://volyume-image-bucket.s3.ap-south-1.amazonaws.com/' + sub + '.png';
            // const image_url = 'https://volyume-image-bucket.s3.ap-south-1.amazonaws.com/filename.png'

            const img = new Image();
            img.src = image_url;

            if (img.complete) {
                setImageExist(true);
                setPreviewUrl(image_url);
            } else {
                img.onload = () => {
                    setImageExist(true);
                    setPreviewUrl(image_url);
                };

                img.onerror = () => {
                    setImageExist(false);
                };
            }
        })


    }, [])

    const handleImage = (event: any) => {
        let selectedFile = event.target.files[0];

        if (selectedFile && selectedFile.type.startsWith("image/")) {
            setIsImageValid(true);
            setPreviewUrl(URL.createObjectURL(selectedFile));
        } else {
            setIsImageValid(false);
            setPreviewUrl(null);
        }
    }

    const handleProfileSave = async () => {

        setLoadingProgress(true);

        //@ts-ignore
        const imageURI = avatarEditor.current.getImageScaledToCanvas().toDataURL()

        postPhoto(imageURI).then(() => {
            setPreviewUrl(null);
            setIsImageValid(false);
            setImageExist(true);
            setLoadingProgress(false);
        })
        .catch(()=> setLoadingProgress(false));

    }

    return (<>
        <div className="profile-image-input-holder">
            <img src={uploadIcon} className='profile-image-upload-icon' />
            <input className='profile-image-input' id='profile-image' type='file' onChange={handleImage} />
            <label className='profile-image-label' htmlFor="profile-image">{imageExist ? 'Change photo' : 'Upload photo'}</label>
        </div>

        {isImageValid &&
            (<>
                <AvatarEditor
                    ref={avatarEditor}
                    image={previewUrl as string}
                    width={300}
                    height={300}
                    border={30}
                    scale={profileImageScale}
                    borderRadius={150}
                    className='avatar-editor'
                />

                <div className="profile-scale-slider">
                    Scale
                    <Slider
                        value={profileImageScale}
                        //@ts-ignore
                        onChange={(e, val) => setProfileImageScale(parseFloat(val))}
                        step={0.05}
                        // valueLabelFormat={(x) => (x / 1000) + 'k'}
                        min={1}
                        max={5}
                        valueLabelDisplay="auto"
                        className='profile-slider'
                    />
                </div>

                {loadingProgress ?
                    (<CircularProgress className='loading-progress-indicator' />) : (
                        <div className="profile-image-action-buttons">
                            <StyledButton
                                buttonText='Cancel'
                                buttonClass='profile-image-discard'
                                handlerFunction={() => {
                                    setPreviewUrl(null);
                                    setIsImageValid(false)
                                }}
                            />

                            <StyledButton
                                buttonText='Save'
                                buttonClass='profile-image-save'
                                handlerFunction={handleProfileSave}
                            />
                        </div>)
                }

            </>)}

    </>)
}