import { Auth, Hub } from 'aws-amplify';
import React, { createContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { ForgotPassComponent } from '../components/ForgotPassComponent';
import { LoadingDisplay } from '../components/LoadingDisplay';
import { LoginComponent } from '../components/LoginComponent';
import { SignupComponent } from '../components/SignupComponent';
import { UnconfirmedUserComponent } from '../components/UnconfirmedUser';
import { Campaign, LoginStatus, Proposal, PROPSTATUS } from '../interfaces';
import { getCampaigns, getProposals } from '../utilities/backend';

const loadingProposal = {
    prop_id: 'loading',
    brand_id: 'loading',
    name: 'loading',
    brand: 'loading',
    brand_email: 'loading',
    brandHandle: 'loading',
    phone_number: 'loading',
    deliverables: 'loading',
    on_instagram: false,
    on_youtube: false,
    on_moj: false,
    on_facebook: false,
    status: PROPSTATUS.NEW
};

const loadingAuth: LoginStatus = { loading: true, isLoggedIn: false, userDetails: { name: 'loading', email: 'loading' } };

export const BaseContext = createContext<{
    proposals: Array<Proposal>,
    getProposalById: (id: string) => Proposal,
    loginStatus: LoginStatus,
    refreshProposals: () => void
}>({
    proposals: [loadingProposal],
    getProposalById: (id) => loadingProposal,
    loginStatus: loadingAuth,
    refreshProposals: () => null
});

export const BaseProvider = (props: any) => {

    const navigate = useNavigate();

    const [proposals, setProposals] = useState<Array<Proposal>>([loadingProposal]);
    const [loginStatus, setLoginStatus] = useState<LoginStatus>({ loading: true, isLoggedIn: false, userDetails: undefined });

    const getToken = () => {
        Auth.currentSession().then((session) => {

            const { name, email } = session.getIdToken().payload;
            setLoginStatus({ loading: false, isLoggedIn: true, userDetails: { name, email } })
            getProposals().then((props) => setProposals(props));
        })
            .catch(() => setLoginStatus({ loading: false, isLoggedIn: false, userDetails: undefined }))
    }

    //@ts-ignore
    const getProposalById: (proposal_id: string) => campaign = (proposal_id: string) => {
        if (proposals[0].prop_id === 'loading') return proposals[0];

        return proposals.find((prop) => prop.prop_id === proposal_id);
    }

    Hub.listen("auth", ({ payload: { event, data } }) => {

        switch (event) {
            case "signIn":
                getToken();
                break;

            case "signIn_failure":
                if (data.name === 'UserNotConfirmedException') { navigate('/awaiting-approval') }
                break;

            case "signOut":
                getToken();
                break;
        }
    });

    useEffect(() => {
        getToken();
    }, [])

    if (loginStatus.loading) return (<LoadingDisplay processMessage='Authenticating' />);

    if (!loginStatus.isLoggedIn) return (
        <Routes >
            {/* <Route element={<HomeComponent />} path={'/'} /> */}
            <Route element={<LoginComponent />} path={'/login'} />
            <Route element={<ForgotPassComponent />} path={'/forgot'} />
            <Route element={<SignupComponent />} path={'/signup'} />
            <Route element={<UnconfirmedUserComponent />} path={'/awaiting-approval'} />

            <Route path='*' element={<Navigate to="/login" />} />
        </Routes>
    )

    return (
        <BaseContext.Provider value={{ proposals, getProposalById, loginStatus, refreshProposals:getToken }}>{props.children}</BaseContext.Provider>
    )
}